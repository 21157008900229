import ManagementClient from "clients/managementClient";
import {
  ICalculatorAdviceRequestProps,
  IRegistrationCalculatorAdviceProps,
} from "components/calculatorAdvice/interfaces/ICalculatorAdviceProps";
import { ISendCalculationEmailRequestProps } from "components/sendCalculationEmail/interfaces/ISendCalculationEmailProps";
import {
  ISubscribeToWaitingListRequestProps,
  IUpsertZipCodeRequestProps,
} from "components/subscribeToWaitingList/interfaces/ISubscribeToWaitingListProps";
import { StorageItems } from "constants/storageItems";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import {
  IRegistrationWizardAddressByZipCodeRequestProps,
  IRegistrationWizardAddressProps,
  IRegistrationWizardCalculationRequestProps,
  IRegistrationWizardCalculationResponseProps,
  IRegistrationWizardCalculatorProps,
  IRegistrationWizardDiscountResponseProps,
  IRegistrationWizardPersonalInformationProps,
  IRegistrationWizardRegisterRequestProps,
  IRegistrationWizardSummaryProps,
  IRegistrationWizardZipCodeProps,
} from "interfaces/IRegistrationWizard";
import { IValidateDiscountCodeRequestProps } from "pages/discount/interfaces/IDiscountProps";
import LanguageProvider from "providers/languageProvider";
import LocalStorageProvider from "providers/localStorageProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { NotificationManager } from "react-notifications";
import AccountService from "services/accountService";
import { ActionTypes } from "store/actionTypes";
import { AppAction, actionCreator } from "store/appAction";
import { IRegistrationStoreState, initialRegistrationStoreState } from "store/state/registrationState";

import { setAsLoading, unsetAsLoading } from "./loaderActions";

export const fetchedAddress = actionCreator<ActionTypes.FETCHED_ADDRESS, IRegistrationWizardAddressProps>(
  ActionTypes.FETCHED_ADDRESS
);

export const fetchedOffer = actionCreator<ActionTypes.FETCHED_OFFER, IRegistrationWizardCalculationResponseProps>(
  ActionTypes.FETCHED_OFFER
);

export const fetchedOfferAdvice = actionCreator<
  ActionTypes.FETCHED_OFFER_ADVICE,
  IRegistrationWizardCalculationResponseProps
>(ActionTypes.FETCHED_OFFER_ADVICE);

export const fetchedZipCodeValid = actionCreator<ActionTypes.FETCHED_ZIP_CODE_VALID, boolean>(
  ActionTypes.FETCHED_ZIP_CODE_VALID
);

export const fetchedAddressAlreadyRegistered = actionCreator<ActionTypes.FETCHED_ADDRESS_ALREADY_REGISTERED, boolean>(
  ActionTypes.FETCHED_ADDRESS_ALREADY_REGISTERED
);

export const fetchedAddressExists = actionCreator<ActionTypes.FETCHED_ADDRESS_EXISTS, boolean>(
  ActionTypes.FETCHED_ADDRESS_EXISTS
);

export const updatedZipCodeData = actionCreator<ActionTypes.UPDATED_ZIP_CODE_DATA, IRegistrationWizardZipCodeProps>(
  ActionTypes.UPDATED_ZIP_CODE_DATA
);

export const updatedDataCalculator = actionCreator<
  ActionTypes.UPDATED_DATA_CALCULATOR,
  IRegistrationWizardCalculatorProps
>(ActionTypes.UPDATED_DATA_CALCULATOR);

export const updatedDataCalculatorCalculation = actionCreator<
  ActionTypes.UPDATED_DATA_CALCULATOR_CALCULATION,
  IRegistrationWizardCalculationRequestProps
>(ActionTypes.UPDATED_DATA_CALCULATOR_CALCULATION);

export const updatedDataCleanerHourlyRate = actionCreator<ActionTypes.UPDATED_DATA_CLEANER_HOURLY_RATE, number>(
  ActionTypes.UPDATED_DATA_CLEANER_HOURLY_RATE
);

export const updatedDataPersonalInformation = actionCreator<
  ActionTypes.UPDATED_DATA_PERSONAL_INFORMATION,
  IRegistrationWizardPersonalInformationProps
>(ActionTypes.UPDATED_DATA_PERSONAL_INFORMATION);

export const updatedDataSummary = actionCreator<ActionTypes.UPDATED_DATA_SUMMARY, IRegistrationWizardSummaryProps>(
  ActionTypes.UPDATED_DATA_SUMMARY
);

export const updatedDataCalculatorSelectedWeeklyHours = actionCreator<
  ActionTypes.UPDATED_DATA_CALCULATOR_SELECTED_WEEKLY_HOURS,
  number
>(ActionTypes.UPDATED_DATA_CALCULATOR_SELECTED_WEEKLY_HOURS);

export const updatedDataCalculatorValid = actionCreator<ActionTypes.UPDATED_DATA_CALCULATOR_VALID, boolean>(
  ActionTypes.UPDATED_DATA_CALCULATOR_VALID
);

export const updatedModalCalculatorAdviceShown = actionCreator<
  ActionTypes.UPDATED_MODAL_CALCULATOR_ADVICE_SHOWN,
  boolean
>(ActionTypes.UPDATED_MODAL_CALCULATOR_ADVICE_SHOWN);

export const updatedModalSendCalculationEmailShown = actionCreator<
  ActionTypes.UPDATED_MODAL_SEND_CALCULATION_EMAIL_SHOWN,
  boolean
>(ActionTypes.UPDATED_MODAL_SEND_CALCULATION_EMAIL_SHOWN);

export const updateDataCalculatorAdvice = actionCreator<
  ActionTypes.UPDATED_DATA_CALCULATOR_ADVICE,
  IRegistrationCalculatorAdviceProps
>(ActionTypes.UPDATED_DATA_CALCULATOR_ADVICE);

export const updateDataCalculatorAdviceEstimatedWeeklyHours = actionCreator<
  ActionTypes.UPDATED_DATA_CALCULATION_ADVICE_ESTIMATED_WEEKLY_HOURS,
  number
>(ActionTypes.UPDATED_DATA_CALCULATION_ADVICE_ESTIMATED_WEEKLY_HOURS);

export const updatedDataDiscountCode = actionCreator<ActionTypes.UPDATED_DATA_DISCOUNT_CODE, string>(
  ActionTypes.UPDATED_DATA_DISCOUNT_CODE
);

export const updatedDataDiscount = actionCreator<
  ActionTypes.UPDATED_DATA_DISCOUNT,
  IRegistrationWizardDiscountResponseProps
>(ActionTypes.UPDATED_DATA_DISCOUNT);

export function setUpdateModalCalculatorAdviceShown(modalCalculatorAdviceShown: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updatedModalCalculatorAdviceShown(modalCalculatorAdviceShown));
  };
}

export function getUnitPriceCurrentlyActive(unitPriceCode: string): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.UnitPriceRetrieving));

    const managementClient = new ManagementClient();
    managementClient
      .getUnitPriceCurrentlyActiveAsync(unitPriceCode)
      .then((rate) => {
        dispatch(updatedDataCleanerHourlyRate(rate));
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.UnitPriceRetrieving)));
  };
}

export const initialRegistraionStateFromLocalStorage = actionCreator<
  ActionTypes.INITIALIZE_REGISTRATION_STATE_FROM_LOCAL_STORAGE,
  IRegistrationStoreState
>(ActionTypes.INITIALIZE_REGISTRATION_STATE_FROM_LOCAL_STORAGE);

export function setUpdateModalSendCalculationEmailShown(isModalSendCalculationEmailShown: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updatedModalSendCalculationEmailShown(isModalSendCalculationEmailShown));
  };
}

export function getReadCalculation(data: IRegistrationWizardCalculationRequestProps): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.PriceChecking));
    const accountService = new AccountService();
    accountService
      .calculateAsync(data)
      .then((offer) => dispatch(fetchedOffer(offer)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.PriceChecking)));
  };
}

export function getReadCalculationAdvice(data: ICalculatorAdviceRequestProps): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.CalculatorAdviceChecking));
    const accountService = new AccountService();
    accountService
      .caluculatorAdvicecalculateAsync(data)
      .then((offer) => dispatch(fetchedOfferAdvice(offer)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.CalculatorAdviceChecking)));
  };
}

export function getReadAddress(
  data: IRegistrationWizardAddressByZipCodeRequestProps,
  callback: (value) => void
): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.AddressChecking));

    const accountService = new AccountService();
    accountService
      .getAddressAsync(data.zipCode, data.houseNumber)
      .then((value) => {
        dispatch(fetchedAddress(value));

        const addressExists = value.zipCode !== null ? true : false;
        dispatch(fetchedAddressExists(addressExists));
        callback(value);
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.AddressChecking)));
  };
}

export function getAddressAlreadyRegisteredAsync(
  data: IRegistrationWizardAddressByZipCodeRequestProps,
  callback: (isRegistered: boolean) => void
): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.AddressRegisteredChecking));

    const accountService = new AccountService();
    accountService
      .getAddressAlreadyRegisteredAsync(data.houseNumber, data.zipCode, data.houseNumberAddition)
      .then((isRegistered) => {
        dispatch(fetchedAddressAlreadyRegistered(isRegistered));
        callback(isRegistered);
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.AddressRegisteredChecking)));
  };
}

export function getReadZipCodeCheck(data: IRegistrationWizardZipCodeProps, callback: () => void): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.ZipCodeChecking));
    dispatch(updatedZipCodeData(data));

    const accountService = new AccountService();
    accountService
      .getCheckActiveRegionAsync(data.zipCode)
      .then((isValid) => {
        dispatch(fetchedZipCodeValid(isValid));
        callback();
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.ZipCodeChecking)));
  };
}

export function setUpdateZipCodeValid(isValid: boolean): AppAction {
  return (dispatch): void => {
    dispatch(fetchedZipCodeValid(isValid));
  };
}

export function setUpdateDataCalculatorValid(isValid: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updatedDataCalculatorValid(isValid));
  };
}

export function setUpdateDataCalculatorSelectedWeeklyHours(selectedWeeklyHours: number): AppAction {
  return (dispatch): void => {
    dispatch(updatedDataCalculatorSelectedWeeklyHours(selectedWeeklyHours));
  };
}

export function setUpdateDataCalculatorAdvice(calculatorAdvice: IRegistrationCalculatorAdviceProps): AppAction {
  return (dispatch): void => {
    dispatch(updateDataCalculatorAdvice(calculatorAdvice));
  };
}

export function setUpdateDataCalculatorAdviceEstimatedWeeklyHoursWeeklyHours(selectedWeeklyHours: number): AppAction {
  return (dispatch): void => {
    dispatch(updateDataCalculatorAdviceEstimatedWeeklyHours(selectedWeeklyHours));
  };
}

export function createRegisterAsync(
  data: IRegistrationWizardRegisterRequestProps,
  callback: (value) => void
): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.AccountCreating));
    const accountService = new AccountService();
    accountService
      .createRegisterAsync(data)
      .then((value) => {
        callback(value);
      })

      .catch(() => {
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.registration.account_registration_general.message),
          "",
          999999
        );
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.AccountCreating)));
  };
}

export function sendCalculationEmailAsync(
  data: ISendCalculationEmailRequestProps,
  callback: (value) => void
): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.CalculationEmailSending));
    const accountService = new AccountService();
    accountService
      .sendCalulationMailAsync(data)
      .then((value) => {
        callback(value);
      })
      .catch(() => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.info), "", 999999);
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.CalculationEmailSending)));
  };
}

export function subscribeToWaitingListAsync(
  data: ISubscribeToWaitingListRequestProps,
  callback: (value) => void
): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.SubscribeToWaitingListCreating));
    const accountService = new AccountService();
    accountService
      .subscribeToWaitingListAsync(data)
      .then((value) => {
        callback(value);
      })
      .catch(() => {
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.subscribe_to_waiting_list),
          "",
          999999
        );
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.SubscribeToWaitingListCreating)));
  };
}

export function upsertPostalCodeRequestAsync(data: IUpsertZipCodeRequestProps, callback: () => void): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.SubscribeToWaitingListCreating));
    const managementClient = new ManagementClient();
    managementClient
      .upsertPostalCodeRequestAsync(data)
      .then(() => {
        callback();
      })
      .catch(() => {
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.subscribe_to_waiting_list),
          "",
          999999
        );
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.SubscribeToWaitingListCreating)));
  };
}

export function validateDiscountCodeAsync(
  data: IValidateDiscountCodeRequestProps,
  callback: (isDiscountCodeValid) => void
): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.DiscountCodeValidating));
    const accountService = new AccountService();
    accountService
      .validateDiscountCodeAsync(data)
      .then((value) => {
        if (value.zizoCodeErrorCode === "error_calculate_discount_1") {
          dispatch(updatedDataDiscountCode(""));
          dispatch(updatedDataDiscount(initialRegistrationStoreState.calculator.discount));
          callback(false);
        } else {
          LocalStorageProvider.set(StorageItems.Calculator, {});
          dispatch(updatedDataDiscount(value));

          dispatch(
            updatedDataCalculatorCalculation({
              cleaningFrequency: initialRegistrationStoreState.calculator.calculation.cleaningFrequency,
              houseType: initialRegistrationStoreState.calculator.calculation.houseType,
              houseSurface: initialRegistrationStoreState.calculator.calculation.houseSurface,
              includeIroning: initialRegistrationStoreState.calculator.calculation.includeIroning,
              selectedWeeklyHours: initialRegistrationStoreState.calculator.calculation.selectedWeeklyHours,
              selectedWeeklyIroningHours:
                initialRegistrationStoreState.calculator.calculation.selectedWeeklyIroningHours,
              discountCode: data.discountCode,
              useSelectedWeeklyHoursInCalculation:
                initialRegistrationStoreState.calculator.calculation.useSelectedWeeklyHoursInCalculation,
            })
          );

          callback(true);
        }
      })
      .catch(() => {
        callback(false);
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.DiscountCodeValidating)));
  };
}

export function clearDiscountCode(): AppAction {
  return (dispatch): void => {
    dispatch(updatedDataDiscountCode(""));
    dispatch(updatedDataDiscount(initialRegistrationStoreState.calculator.discount));
  };
}

export function setUpdateDataCalculator(data: IRegistrationWizardCalculatorProps): AppAction {
  return (dispatch): void => {
    dispatch(updatedDataCalculator(data));
  };
}

export function setUpdateDataPersonalInformation(data: IRegistrationWizardPersonalInformationProps): AppAction {
  return (dispatch): void => {
    dispatch(updatedDataPersonalInformation(data));
  };
}

export function setUpdateDataSummary(data: IRegistrationWizardSummaryProps): AppAction {
  return (dispatch): void => {
    dispatch(updatedDataSummary(data));
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function setRegistrationInitialValuesWithStorage(): any {
  const localStorageRegistrationCalculator = LocalStorageProvider.get(StorageItems.Calculator) || {};

  // Session Storage (not needed or may not be kept in Local Storage)
  // Session Storage used to store address/zipcode in the frontend (user input)
  const sessionRegistrationAddress = SessionStorageProvider.get(StorageItems.Address) || false;
  // Session Storage used to store the address/zipcode received from the backend, checked by Zipcode API
  const sessionStorageRegistrationCalculation = SessionStorageProvider.get(StorageItems.Calculation) || {};
  const sessionStorageRegistrationIsZipCodeValid = SessionStorageProvider.get(StorageItems.ZipCodeIsChecked) || false;
  const sessionStorageRegistrationAddressAlreadyRegistered =
    SessionStorageProvider.get(StorageItems.AddressAlreadyRegistered) || false;
  const sessionStorageAddressExists = SessionStorageProvider.get(StorageItems.AddressExists) || false;
  const sessionStorageRegistrationPersonalInformation =
    SessionStorageProvider.get(StorageItems.PersonalInformation) || {};
  const sessionStorageRegistrationSummary = SessionStorageProvider.get(StorageItems.Summary) || {};
  const sessionStorageRegistrationZipCode = SessionStorageProvider.get(StorageItems.ZipCode) || {};

  const updatedState: IRegistrationStoreState = {
    address: {
      city: sessionRegistrationAddress.city ?? initialRegistrationStoreState.address.city,
      houseNumber: sessionRegistrationAddress.houseNumber ?? initialRegistrationStoreState.address.houseNumber,
      houseNumberAddition:
        sessionRegistrationAddress.houseNumberAddition ?? initialRegistrationStoreState.address.houseNumberAddition,
      street: sessionRegistrationAddress.street ?? initialRegistrationStoreState.address.street,
      zipCode: sessionRegistrationAddress.zipCode ?? initialRegistrationStoreState.address.zipCode,
    },
    calculation: {
      costEmployee:
        sessionStorageRegistrationCalculation.costEmployee ?? initialRegistrationStoreState.calculation.costEmployee,
      costEmployer: initialRegistrationStoreState.calculation.costEmployer,
      estimatedWeeklyHours:
        sessionStorageRegistrationCalculation.estimatedWeeklyHours ??
        initialRegistrationStoreState.calculation.estimatedWeeklyHours,
      employerMaxMonthlyCompensation: initialRegistrationStoreState.calculation.employerMaxMonthlyCompensation,
      percentageEmployer: initialRegistrationStoreState.calculation.percentageEmployer,
    },
    calculationAdvice: {
      costEmployee:
        sessionStorageRegistrationCalculation.costEmployee ??
        initialRegistrationStoreState.calculationAdvice.costEmployee,
      estimatedWeeklyHours:
        sessionStorageRegistrationCalculation.estimatedWeeklyHours ??
        initialRegistrationStoreState.calculationAdvice.estimatedWeeklyHours,
    },
    calculatorAdvice: {
      isModalCalculatorAdviceShown: initialRegistrationStoreState.calculatorAdvice.isModalCalculatorAdviceShown,
      livingRoom: initialRegistrationStoreState.calculatorAdvice.livingRoom,
      bedroom: initialRegistrationStoreState.calculatorAdvice.bedroom,
      entrance: initialRegistrationStoreState.calculatorAdvice.entrance,
      kitchen: initialRegistrationStoreState.calculatorAdvice.kitchen,
      bathroom: initialRegistrationStoreState.calculatorAdvice.bathroom,
      toilet: initialRegistrationStoreState.calculatorAdvice.toilet,
      landing: initialRegistrationStoreState.calculatorAdvice.landing,
      otherRooms: initialRegistrationStoreState.calculatorAdvice.otherRooms,
    },
    calculator: {
      calculation: {
        cleaningFrequency: localStorageRegistrationCalculator.calculation?.cleaningFrequency
          ? localStorageRegistrationCalculator.calculation.cleaningFrequency
          : initialRegistrationStoreState.calculator.calculation.cleaningFrequency,
        houseType: initialRegistrationStoreState.calculator.calculation.houseType,
        houseSurface: initialRegistrationStoreState.calculator.calculation.houseSurface,
        includeIroning: localStorageRegistrationCalculator.calculation?.includeIroning
          ? localStorageRegistrationCalculator.calculation.includeIroning
          : initialRegistrationStoreState.calculator.calculation.includeIroning,
        selectedWeeklyHours: localStorageRegistrationCalculator.calculation?.selectedWeeklyHours
          ? localStorageRegistrationCalculator.calculation.selectedWeeklyHours
          : initialRegistrationStoreState.calculator.calculation.selectedWeeklyHours,
        selectedWeeklyIroningHours: localStorageRegistrationCalculator?.calculation?.selectedWeeklyIroningHours
          ? localStorageRegistrationCalculator.calculation.selectedWeeklyIroningHours
          : initialRegistrationStoreState.calculator.calculation.selectedWeeklyIroningHours,

        // is required for the calculation in the backend
        useSelectedWeeklyHoursInCalculation:
          initialRegistrationStoreState.calculator.calculation.useSelectedWeeklyHoursInCalculation,
      },
      discount: {
        availableFrequencies: initialRegistrationStoreState.calculator.discount.availableFrequencies,
        calculatorType: initialRegistrationStoreState.calculator.discount.calculatorType,
        zizoCodeErrorCode: initialRegistrationStoreState.calculator.discount.zizoCodeErrorCode,
      },
      isValid: localStorageRegistrationCalculator.isValid || initialRegistrationStoreState.calculator.isValid,
      isZipCodeValid:
        sessionStorageRegistrationIsZipCodeValid || initialRegistrationStoreState.calculator.isZipCodeValid,
      addressExists: sessionStorageAddressExists || initialRegistrationStoreState.zipCode.addressExists,
    },
    isModalSendCalculationEmailShown: initialRegistrationStoreState.isModalSendCalculationEmailShown,
    personalInformation: {
      emailAddress:
        sessionStorageRegistrationPersonalInformation.emailAddress ??
        initialRegistrationStoreState.personalInformation.emailAddress,
      firstName:
        sessionStorageRegistrationPersonalInformation.firstName ??
        initialRegistrationStoreState.personalInformation.firstName,
      isValid:
        sessionStorageRegistrationPersonalInformation.isValid ??
        initialRegistrationStoreState.personalInformation.isValid,
      lastName:
        sessionStorageRegistrationPersonalInformation.lastName ??
        initialRegistrationStoreState.personalInformation.lastName,
      phoneNumber:
        sessionStorageRegistrationPersonalInformation.phoneNumber ??
        initialRegistrationStoreState.personalInformation.phoneNumber,
      prefix:
        sessionStorageRegistrationPersonalInformation.prefix ??
        initialRegistrationStoreState.personalInformation.prefix,
    },
    summary: {
      directDebt: initialRegistrationStoreState.summary.directDebt,
      privacyPolicy: initialRegistrationStoreState.summary.privacyPolicy,
      termsOfDelivery: initialRegistrationStoreState.summary.termsOfDelivery,
      payment: {
        iban: sessionStorageRegistrationSummary?.payment?.iban ?? initialRegistrationStoreState.summary.payment.iban,
        automaticCollection:
          sessionStorageRegistrationSummary?.payment?.automaticCollection ??
          initialRegistrationStoreState.summary.payment.automaticCollection,
      },
      isValid: initialRegistrationStoreState.summary.isValid,
    },
    zipCode: {
      houseNumber: sessionStorageRegistrationZipCode.houseNumber ?? initialRegistrationStoreState.zipCode.houseNumber,
      houseNumberAddition:
        sessionStorageRegistrationZipCode.houseNumberAddition ??
        initialRegistrationStoreState.zipCode.houseNumberAddition,
      isZipCodeValid: sessionStorageRegistrationIsZipCodeValid ?? initialRegistrationStoreState.zipCode.isZipCodeValid,
      addressExists: sessionStorageAddressExists ?? initialRegistrationStoreState.zipCode.addressExists,
      alreadyRegistered:
        sessionStorageRegistrationAddressAlreadyRegistered ?? initialRegistrationStoreState.zipCode.alreadyRegistered,
      zipCode: sessionStorageRegistrationZipCode.zipCode ?? initialRegistrationStoreState.zipCode.zipCode,
    },
  };

  // Dispatch an action to update the state
  return (dispatch): void => {
    dispatch(initialRegistraionStateFromLocalStorage(updatedState));
  };
}
/* eslint-enable @typescript-eslint/no-explicit-any */
